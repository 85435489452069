import { DocumentProps } from '~/editor/document/document.interfaces'
import { imageLoader } from '~/editor/image/image'
import Page from '~/editor/page/page'
import { Page as PageData } from '~/types/comic/page'
import { ActiveSelection, Canvas, FabricObject, TPointerEventInfo, util } from 'fabric'
import { resetTranslations, scaleTranslations } from '~/services/current-document/translations'
import { history } from '~/editor/history'
import TypesetTextsMouseHandler from '~/editor/mouse-handler/typesettexts-mouse-handler'
import TranslationsMouseHandler from '~/editor/mouse-handler/translations-mouse-handler'
import { scaleTypesetTexts } from '~/services/current-document/typeset-texts'
import { Job } from '~/types/comic/chapter'

class Document extends EventTarget{
  canvas: Canvas
  canvasViewport: HTMLDivElement
  scrollableArea: HTMLDivElement
  width: number
  pages: Page[]
  hasFocus: boolean
  documentHeight: number
  settings: {
    mode: Job
    readOnly: boolean
  }
  selection: ActiveSelection | null
  onScrollHandler?: (top: number) => void

  constructor({ canvasElement, canvasViewport, scrollableArea, settings }: DocumentProps){
    super()
    this.canvas = new Canvas(canvasElement, {
      selection: !settings.readOnly
    })
    this.canvasViewport = canvasViewport
    this.scrollableArea = scrollableArea
    this.canvas.imageSmoothingEnabled = true
    this.width = this.canvasViewport.offsetWidth
    this.documentHeight = 0
    this.settings = {
      mode: settings.mode,
      readOnly: settings.readOnly !== undefined ? settings.readOnly : false
    }
    this.selection = null
    this.pages = []
    this.hasFocus = !this.settings.readOnly
    history.clear()
    
    this.registerEventListeners()
  }

  async loadPages(pages: PageData[]){
    this.reset()
    const imageLoaders: Promise<HTMLImageElement>[] = []
    pages.forEach(page => {
      imageLoaders.push(imageLoader(page.url))
    })

    await Promise.all(imageLoaders).then(images => {
      // Create Fabric Images for each page
      images.forEach((image, index) => {
        const pageId = pages.find(entry => entry.url === image.src)?.id
        if(pageId){
          const page = new Page({
            document: this,
            image,
            id: pageId,
            index
          })
          this.pages.push(page)
          this.canvas.add(page.image)
          this.canvas.add(page.pageIndex)
          this.canvas.sendObjectToBack(page.image)
        }
      })

      // Scale at current zoom
      this.onCanvasResize()
      this.dispatchEvent(new Event('pagesready'))
    })
  }

  add(element: FabricObject | FabricObject[]) {
    const objects = Array.isArray(element) ? element : [element]
    this.canvas.add(...objects)
  }

  remove(element: FabricObject | FabricObject[]){
    const objects = Array.isArray(element) ? element : [element]
    this.canvas.remove(...objects)
  }

  reset(){
    this.pages = []
    if(this.settings.mode === 'translation' || this.settings.mode === 'proofreading') resetTranslations()
    this.canvas.clear()
  }

  scalePages(){
    this.documentHeight = 0

    this.pages.forEach(page => {
      // Update the scale according to canvas size & zoom
      // Offset each page after the last one
      page.resize()
      // Compute document height by adding all pages height
      this.documentHeight += page.image.getScaledHeight()
    })

    this.scrollableArea.style.height = `${this.documentHeight}px`

    scaleTranslations()
    scaleTypesetTexts()
    this.onScroll()
  }

  onSelection(){
    const activeObject = this.canvas.getActiveObject()
    if (activeObject?.isType('activeselection')){
      this.selection = activeObject as ActiveSelection
      this.selection.lockRotation = true
      this.selection.setControlVisible('mtr', false)
    }else{
      this.selection = null
    }
  }

  onSelectionClear(){
    this.selection = null
  }

  onObjectMove(){
    const selection = this.canvas.getActiveObjects()
    if (selection.length > 1){
      selection.forEach(object => {
        object.fire('moving')
      })
    }
  }

  onObjectScale(){
    const selection = this.canvas.getActiveObjects()
    if(selection.length > 1){
      selection.forEach(object => {
        object.fire('scaling')
      })
    }
  }

  onObjectRotate(){
    const selection = this.canvas.getActiveObjects()
    if(selection.length > 1){
      selection.forEach(object => {
        object.fire('rotating')
      })
    }
  }

  onObjectMouseUp(){
    const selection = this.canvas.getActiveObjects()
    if(selection.length > 1){
      selection.forEach(object => {
        object.fire('mouseup')
      })
    }
  }

  onKeyPress(event: KeyboardEvent){
    const chordKey = window.navigator.platform.includes('Mac') ? event.metaKey : event.ctrlKey
    if(!this.settings.readOnly && this.hasFocus){
      // Delete
      if(event.code === "Delete" || event.code === "Backspace"){
        this.remove(this.canvas.getActiveObjects())
      }
      // Undo/Redo
      if(event.code === "KeyW" && chordKey && !event.shiftKey){
        event.preventDefault()
        history.undo()
      }
      if((event.code === "KeyY" && chordKey) || (event.code === "KeyW" && chordKey && event.shiftKey)){
        event.preventDefault()
        history.redo()
      }
    }
  }

  onMouseWheel(event: TPointerEventInfo<WheelEvent>){
    this.canvasViewport.scrollBy({ top: event.e.deltaY })
  }

  onScroll(){
    const transformMatrix = util.composeMatrix({
      translateY: -1 * this.canvasViewport.scrollTop
    })
    if (this.onScrollHandler) this.onScrollHandler(this.canvasViewport.scrollTop)
    this.canvas.setViewportTransform(transformMatrix)
    this.canvas.renderAll()
  }

  render(){
    this.canvas.renderAll()
  }

  onCanvasResize(){
    this.canvas.discardActiveObject()
    this.canvas.setDimensions({
      height: this.canvasViewport.offsetHeight,
      width: this.scrollableArea.offsetWidth
    })
    this.width = this.canvasViewport.offsetWidth
    this.scalePages()
  }

  scrollTo(top: number){
    this.canvasViewport.scrollTo({ top })
  }

  registerEventListeners(){
    if (!this.settings.readOnly){
      if(this.settings.mode === 'typesetting'){
        new TypesetTextsMouseHandler(this)
      }else{
        new TranslationsMouseHandler(this)
      }
    }
    this.canvas.on('mouse:wheel', (event) => this.onMouseWheel(event))
    this.canvas.on('selection:created', () => this.onSelection())
    this.canvas.on('selection:updated', () => this.onSelection())
    this.canvas.on('selection:cleared', () => this.onSelectionClear())
    this.canvas.on('object:moving', () => this.onObjectMove())
    this.canvas.on('object:scaling', () => this.onObjectScale())
    this.canvas.on('object:rotating', () => this.onObjectRotate())
    this.canvas.on('mouse:up', () => this.onObjectMouseUp())
    this.canvasViewport.addEventListener('scroll', () => this.onScroll())
    window.addEventListener('resize', () => this.onCanvasResize())
    window.addEventListener('keydown', (event) => this.onKeyPress(event))
  }
}

export default Document