import styles from '~/components/canvas-view/canvas-view.module.scss'
import { Component, onMount } from 'solid-js'
import { CanvasViewProps } from '~/components/canvas-view/canvas-view.interfaces'

const CanvasView: Component<CanvasViewProps> = ({ settings, onReady, invisible }) => {
  let canvasViewport: HTMLDivElement | undefined
  let canvasElement: HTMLCanvasElement | undefined
  let scrollableArea: HTMLDivElement | undefined

  const cssStyles = {
    [styles.canvasViewport]: true,
    [styles.invisible]: invisible
  }

  onMount(() => {
    if (canvasViewport && canvasElement && scrollableArea){
      onReady({ canvasElement, canvasViewport, scrollableArea, settings })
    }
  })

  return (
    <div classList={cssStyles} ref={canvasViewport}>
      <div class={styles.scrollableArea} ref={scrollableArea} />
      <div class={styles.canvas}>
        <canvas ref={canvasElement} />
      </div>
    </div>
  )
}

export default CanvasView