import { Component } from 'solid-js'
import styles from '~/components/sidebar-header/sidebar-header.module.scss'
import { translations, wordCount } from '~/services/current-document/translations'
import { translations as translationsDict } from '~/translations'

const TranslationStats: Component = () => {
  const stats = () => {
    return wordCount(translations())
  }
  
  return (
    <>
      <div class={styles.line}>
        <div class={styles.label}>
          {translationsDict().editor.translation.header.originalWordCount}
        </div>
        <div class={styles.value}>
          <strong>{stats().original.characters}</strong> {translationsDict().editor.translation.header.chars}
        </div>
      </div>
      <div class={styles.line}>
        <div class={styles.label}>
          {translationsDict().editor.translation.header.targetWordCount}
        </div>
        <div class={styles.value}>
          <strong>{stats().translation.words}</strong> {translationsDict().editor.translation.header.words} <strong>{stats().translation.characters}</strong> {translationsDict().editor.translation.header.chars}
        </div>
      </div>
    </>
  )
}

export default TranslationStats