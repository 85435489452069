import Document from '~/editor/document/document'
import { Point, TPointerEvent, TPointerEventInfo } from 'fabric'
import createFromCoords from '~/editor/translation/create'

class TranslationsMouseHandler{
  document: Document
  pointerStart: Point
  pointerEnd: Point

  constructor(document: Document){
    this.document = document
    this.pointerStart = new Point(0, 0)
    this.pointerEnd = new Point(0, 0)
    this.registerEventListeners()
  }

  onMouseDown(event: TPointerEventInfo<TPointerEvent>){
    if('button' in event.e && event.e.button === 0){
      this.pointerStart.setXY(event.viewportPoint.x, event.viewportPoint.y)
    }
  }

  onMouseUp(event: TPointerEventInfo<TPointerEvent>){
    if('button' in event.e && event.e.button === 0){
      this.pointerEnd.setXY(event.viewportPoint.x, event.viewportPoint.y)
      if(event.target?.isType("Image")){
        this.onSelectionBox()
      }
    }
  }

  onSelectionBox(){
    const scrollTop = this.document.canvasViewport.scrollTop
    const page = this.document.pages.find(page => {
      const pageBottomY = - scrollTop + page.image.top + page.image.getScaledHeight()
      return pageBottomY > this.pointerStart.y
    })
    if(page){
      createFromCoords({
        pointerStart: this.pointerStart,
        pointerEnd: this.pointerEnd,
        page
      })
    }
  }

  registerEventListeners(){
    this.document.canvas.on('mouse:up', event => this.onMouseUp(event))
    this.document.canvas.on('mouse:down', event => this.onMouseDown(event))
  }
}

export default TranslationsMouseHandler