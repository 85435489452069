import { v4 as uuidv4 } from 'uuid'
import Document from '~/editor/document/document'
import { Point, TPointerEvent, TPointerEventInfo } from 'fabric'
import TypesetText from '~/editor/typeset-text/typeset-text'
import { createTypesetText } from '~/services/current-document/typeset-texts'
import { TypesetTextBoundingBox } from '~/editor/typeset-text/typeset-text.interfaces'

class TypesetTextsMouseHandler{
  document: Document

  constructor(document: Document){
    this.document = document
    this.registerEventListeners()
  }

  addTypesetText(event: TPointerEventInfo<TPointerEvent>) {
    const hasSelection = this.document.canvas.getActiveObject()
    if ('button' in event.e && event.e.button === 0 && !hasSelection) {
      const page = this.document.pages.find(page => {
        const pageBottomY = page.image.top + page.image.getScaledHeight()
        return pageBottomY > event.pointer.y
      })
      if (page) {
        const startPoint = new Point(
          event.pointer.x / page.image.getScaledWidth(),
          (event.pointer.y - page.image.top) / page.image.getScaledHeight()
        )
        const endPoint = startPoint.add({ x: 0.1, y: 0.1 })
        const relativeWidth = Math.abs(startPoint.x - endPoint.x)
        const absoluteWidth = relativeWidth * page.image.getScaledWidth()
        const boundingBox: TypesetTextBoundingBox = {
          startPoint,
          relativeWidth,
          absoluteWidth
        }
        const typesetText = new TypesetText({
          id: uuidv4(),
          text: 'Insert text',
          boundingBox,
          date: new Date().toUTCString(),
          page
        })
        createTypesetText(typesetText)
      }
    }
  }

  registerEventListeners(){
    // this.document.canvas.on('mouse:up', (event) => this.addTypesetText(event))
  }
}

export default TypesetTextsMouseHandler