import { supabase } from '~/services/supabase'
import { reportError } from '~/services/errors'
import { Database } from '~/types/supabase'
import { CreateCommentPayload, DeleteCommentPayload, UpdateCommentPayload } from '~/types/database/comments'
import { Comment } from '~/types/comic/comments'

const createComment = async (payload: CreateCommentPayload) => {
  const row: Database['public']['Tables']['comments']['Insert'] = payload

  const { data: commentData, error: commentError } = await supabase
    .from('comments')
    .insert([row])
    .select()
    .single()

  if (commentError) {
    reportError(commentError)
    throw commentError
  }

  return commentData
}

const updateComment = async (payload: UpdateCommentPayload) => {
  const row: Database['public']['Tables']['comments']['Update'] = {}
  if (payload.text) row.text = payload.text
  if (payload.resolved) row.resolved = payload.resolved

  const { data, error } = await supabase
    .from('comments')
    .update(row)
    .eq('id', payload.id)
    .select()

  if (error) {
    reportError(error)
    throw error
  }

  return data
}

const deleteComment = async (payload: DeleteCommentPayload) => {
  const { data, error } = await supabase
    .from('comments')
    .delete()
    .eq('id', payload.id)

  if (error) {
    reportError(error)
    throw error
  }

  return data
}

const getComments = async (chapterId: Database['public']['Tables']['chapters']['Row']['id']) => {
  const { data, error } = await supabase
    .from('comments')
    .select('*, user:users(name)')
    .eq('chapter_id', chapterId)
    .eq('deleted', false)

  if (error) {
    reportError(error)
  }

  return data ?? [] as Comment[]
}

export {
  createComment,
  updateComment,
  deleteComment,
  getComments
}