import { A } from '@solidjs/router'
import { Component, createResource } from 'solid-js'
import styles from '~/components/sidebar-header/sidebar-header.module.scss'
import TranslationIcon from '~/assets/icons/translation.svg'
import ProofreadingIcon from '~/assets/icons/edit-alt.svg'
import TypesettingIcon from '~/assets/icons/caps.svg'
import CheckIcon from '~/assets/icons/document-check.svg'
import EditIcon from '~/assets/icons/edit-alt.svg'
import CloseIcon from '~/assets/icons/close.svg'
import { getSeries } from '~/services/database/series'
import { SidebarHeaderProps } from '~/components/sidebar-header/sidebar-header.interfaces'
import TranslationStats from '~/components/sidebar-header/stats-translation'
import TypesettingStats from '~/components/sidebar-header/stats-typesetting'
import { translations } from '~/translations'

const SidebarHeader: Component<SidebarHeaderProps> = ({ chapter, mode }) => {
  const [series] = createResource(chapter.comicId, getSeries)
  const chapterListLink = () => {
    return `/series/${chapter.comicId}`
  }
  const editLink = () => {
    return `/series/chapter/${chapter.id}/edit`
  }

  const ModeIcon = 
    mode === 'translation' ? TranslationIcon : 
      mode === 'proofreading' ? ProofreadingIcon : 
        mode === 'typesetting' ? TypesettingIcon : CheckIcon

  const modeLabel =
    mode === 'translation' ? translations().editor.steps.translation :
      mode === 'proofreading' ? translations().editor.steps.proofreading :
        mode === 'typesetting' ? translations().editor.steps.typesetting :
          mode === 'validation' ? translations().editor.steps.validation : translations().editor.steps.completed

  const StatsComponent =
    mode === 'translation' ? TranslationStats :
      mode === 'proofreading' ? TranslationStats :
        mode === 'typesetting' ? TypesettingStats : TranslationStats

  return (
    <div class={styles.sidebarHeader}>
      <div class={styles.aboutMode}>
        <div class={styles.mode}>
          <ModeIcon class={styles.icon} />
          <div class={styles.aboutMode}>
            {modeLabel}
          </div>
        </div>
        <div class={styles.stats}>
          <StatsComponent />
        </div>
      </div>

      <div class={styles.aboutChapter}>
        <div class={styles.series}>
          {series.latest?.title}
        </div>
        <div class={styles.chapter}>
          <div class={styles.index}>
            {translations().chapters.row.chapter} {chapter.index}
          </div>
          <A href={editLink()} class={styles.edit}>
            <EditIcon class={styles.icon} />
          </A>
          {chapter.title && (
            <div class={styles.title} title={chapter.title}>
              {chapter.title}
            </div>
          )}
        </div>
      </div>

      <A class={styles.backToChapterList} href={chapterListLink()}>
        <CloseIcon class={styles.icon} />
      </A>
    </div>
  )
}

export default SidebarHeader