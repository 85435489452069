import { Component } from 'solid-js'
import styles from '~/components/sidebar-header/sidebar-header.module.scss'
import { typesetTexts } from '~/services/current-document/typeset-texts'

const TypesettingStats: Component = () => {
  const count = () => typesetTexts().length

  return (
    <>
      <div class={styles.line}>
        <div class={styles.value}>
          <strong>{count()}</strong> lettrages
        </div>
      </div>
    </>
  )
}

export default TypesettingStats