import { v4 as uuidv4 } from 'uuid'
import Document from '~/editor/document/document'
import Translation from '~/editor/translation/translation'
import { PageProps } from '~/editor/page/page.interfaces'
import { Image, Text, Point, TPointerEvent, TPointerEventInfo } from 'fabric'
import { TranslationVersion } from '~/editor/translation/translation.interfaces'
import { createTranslation } from '~/services/current-document/translations'
import { colors, fontFamily } from '~/editor/design-tokens'
import { user } from '~/services/database/users'

class Page {
  id: number
  startPoint: Point
  endPoint: Point
  resizeFactor: number
  document: Document
  image: Image
  index: number
  pageIndex: Text

  constructor({ document, image, id, index }: PageProps) {
    this.id = id
    this.index = index
    this.document = document
    this.startPoint = new Point(0, 0)
    this.endPoint = new Point(0, 0)
    this.resizeFactor = this.document.width / image.width
    this.image = new Image(image, {
      top: 0,
      left: 0,
      scaleX: this.resizeFactor,
      scaleY: this.resizeFactor,
      selectable: false,
      hoverCursor: 'crosshair'
    })
    this.pageIndex = new Text(`Page ${index+1}`, {
      top: 6,
      left: 6,
      fontFamily: fontFamily.primary,
      fontSize: 16,
      fill: colors.note,
      opacity: 0.5,
      selectable: false
    })
  }

  resize(){
    this.resizeFactor = this.document.width / this.image.getElement().width
    this.image.scale(this.resizeFactor)
    const top = this.getPageTop()
    this.image.setY(top)
    this.pageIndex.setY(top)
  }

  getPageTop(){
    let pageTop = 0
    let pageIndex = 0
    while(this.document.pages[pageIndex].id !== this.id){
      pageTop += this.document.pages[pageIndex].image.getScaledHeight()
      pageIndex++
    }
    return pageTop
  }

  // Returns page relative Point from browser pointer coords
  getPointFromPointerCoords(x: number, y: number){
    const scrollTop = this.document.canvasViewport.scrollTop
    const pageDistanceFromTop = this.getPageTop() - scrollTop // positive: page not reached yet
    const pointerYRelativeToPage = y - pageDistanceFromTop
    // add : clamp to page limits (min=0, max=pageHeight)
    let top = pointerYRelativeToPage / this.image.getScaledHeight()
    if(top < 0) top = 0
    if(top > 1) top = 1
    const left = 0.25
    return new Point(left, top)
  }

  onCreate(start: Point, end: Point){
    const id = uuidv4()
    const initialVersion: TranslationVersion = {
      id,
      startPoint: start,
      endPoint: end,
      date: new Date().toUTCString(),
      translatedText: 'translated text',
      originalText: 'original text',
      userId: user()?.id ?? null,
      pageId: this.id
    }
    const translation = new Translation({
      id,
      versions: [initialVersion],
      page: this
    })
    createTranslation(translation, { canvas: true, database: true, view: true, history: true })
  }

  onMouseDown(event: TPointerEventInfo<TPointerEvent>) {
    this.startPoint.setXY(
      event.pointer.x,
      event.pointer.y
    )
  }

  onMouseUp(event: TPointerEventInfo<TPointerEvent>) {
    this.endPoint.setXY(
      event.pointer.x,
      event.pointer.y
    )

    // Convert startPoint and endPoint to percentage based, relative coords
    this.startPoint.setX(this.startPoint.x / this.image.getScaledWidth())
    this.startPoint.setY((this.startPoint.y - this.image.top) / this.image.getScaledHeight())
    this.endPoint.setX(this.endPoint.x / this.image.getScaledWidth())
    this.endPoint.setY((this.endPoint.y - this.image.top) / this.image.getScaledHeight())

    // If selection is too small, create a default box around startPoint
    const minSelectionSize = 0.05 // 15%
    const defaultBoxSize = 0.15 // 15%
    const isSelection = Math.abs(this.startPoint.x - this.endPoint.x) > minSelectionSize
    if (isSelection){
      this.onCreate(this.startPoint, this.endPoint)
    }else{
      // Since coords are in % of page size, we compute boxH to get same Width value but in Height
      const boxH = defaultBoxSize * this.image.getScaledWidth() / this.image.getScaledHeight()
      const start = new Point(this.startPoint.x - defaultBoxSize/2, this.startPoint.y - boxH/2)
      const end = new Point(this.endPoint.x + defaultBoxSize/2, this.endPoint.y + boxH/2)
      this.onCreate(start, end)
    }
  }
}

export default Page